<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" title="零售价导入" width="960px">
    <el-tabs class="no-flex" v-model="tab" @tab-click="handleTabChange">
      <el-tab-pane name="normal" label="统一设置" />
      <el-tab-pane name="province" label="指定省份" />
      <el-tab-pane name="dis" label="指定经销商" />
    </el-tabs>
    <template v-if="tab === 'normal'">
      <div class="head-container no-flex">
        <div class="head-container">
          <el-upload class="filter-item" ref="upload" :action="uploadUrl" :headers="uploadHeaders" :before-upload="handleBeforeUpload" :on-success="handleUploadSuccess" :on-error="handleUploadError" :show-file-list="false" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                  <el-button type="primary" class="filter-item" icon="el-icon-upload2" :loading="uploading">{{btnName}}</el-button>
                </el-upload>
          <el-button type="info" @click="downloadTemplate" class="filter-item" icon="el-icon-download" :loading="downloadLoading">模板下载</el-button>
        </div>
        <div class="v" v-if="sucInfo">
          <span>导入结果：共{{sucInfo.count}}条,成功{{sucInfo.sucCount}}条,失败{{sucInfo.errCount}}条</span>&nbsp;          
          <span>错误详情</span>
          <el-table :data="errList" border style="width: 100%;">
            <el-table-column prop="name" min-width="200" show-overflow-tooltip  label="商品名称" />
            <el-table-column prop="erpCode" header-align="center" width="150" show-overflow-tooltip align="center" label="ERP编码" />
            <el-table-column prop="retailPrice"  show-overflow-tooltip label="零售价" />
            <el-table-column prop="error" label="错误信息" min-width="150" show-overflow-tooltip />
          </el-table>
        </div>
      </div>
    </template>
    <template v-if="tab === 'province'">
      <div class="head-container no-flex">
        <div class="head-container">
          <el-upload class="filter-item" ref="upload" :action="uploadUrl" :headers="uploadHeaders" :before-upload="handleBeforeUpload" :on-success="handleUploadSuccess" :on-error="handleUploadError" :show-file-list="false" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                  <el-button type="primary" class="filter-item" icon="el-icon-upload2" :loading="uploading">{{btnName}}</el-button>
                </el-upload>
          <el-button type="info" @click="downloadTemplate" class="filter-item" icon="el-icon-download" :loading="downloadLoading">模板下载</el-button>
        </div>
        <div class="v" v-if="sucInfo">
          <span>导入结果：共{{sucInfo.count}}条,成功{{sucInfo.sucCount}}条,失败{{sucInfo.errCount}}条</span>&nbsp;          
          <span>错误详情</span>
          <el-table :data="errList" border style="width: 100%;">
            <el-table-column prop="name" min-width="200" show-overflow-tooltip  label="商品名称" />
            <el-table-column prop="erpCode" header-align="center" width="150" show-overflow-tooltip align="center" label="ERP编码" />
            <el-table-column prop="provinceName" width="200" show-overflow-tooltip  label="省份" />            
            <el-table-column prop="retailPrice"  show-overflow-tooltip label="零售价" />
            <el-table-column prop="error"   label="错误信息" min-width="150" show-overflow-tooltip />
          </el-table>
        </div>
      </div>
    </template>
    <template v-if="tab === 'dis'">
      <div class="head-container no-flex">
        <div class="head-container">
          <el-upload class="filter-item" ref="upload" :action="uploadUrl" :headers="uploadHeaders" :before-upload="handleBeforeUpload" :on-success="handleUploadSuccess" :on-error="handleUploadError" :show-file-list="false" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                  <el-button type="primary" class="filter-item" icon="el-icon-upload2" :loading="uploading">{{btnName}}</el-button>
                </el-upload>
          <el-button type="info" @click="downloadTemplate" class="filter-item" icon="el-icon-download" :loading="downloadLoading">模板下载</el-button>
        </div>
        <div class="v" v-if="sucInfo">
          <span>导入结果：共{{sucInfo.count}}条,成功{{sucInfo.sucCount}}条,失败{{sucInfo.errCount}}条</span>&nbsp;          
          <span>错误详情</span>
          <el-table :data="errList" border style="width: 100%;">
            <el-table-column prop="name" min-width="200" show-overflow-tooltip  label="商品名称" />
            <el-table-column prop="erpCode" header-align="center" width="150" show-overflow-tooltip align="center" label="ERP编码" />
            <el-table-column prop="disCode" width="150" show-overflow-tooltip  label="经销商编码" />
            <el-table-column prop="disName" width="150" show-overflow-tooltip  label="经销商" />            
            <el-table-column prop="retailPrice"  show-overflow-tooltip label="零售价" />
            <el-table-column prop="error"   label="错误信息" min-width="150" show-overflow-tooltip />
          </el-table>
        </div>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import config from "@/config";
import { getToken } from "@/utils/auth";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
export default {
  data() {
    return {
      tab:"normal",
      uploading: false,
      uploadUrl: config.hosts.request + "api/goods/sku/retail/import",
      templateUrl: "api/goods/sku/template",
      uploadHeaders: {},
      downloadLoading: false,
      dialog: false,
      errList:[],
      sucInfo:null,
      errInfo:null,
    }
  },
  computed:{
    btnName(){ 
      return this.uploading?"导入中":"选择文件";
    }
  },
  methods:{
    handleTabChange(tab){
      this.errList=[];
      this.sucInfo = null;
      if(tab.name==='normal'){
        this.uploadUrl = config.hosts.request + "api/goods/sku/retail/import";
        this.templateUrl = "api/goods/sku/template";
      }else if(tab.name==='province'){
        this.uploadUrl = config.hosts.request + "api/shop/province/retail/import";
        this.templateUrl = "api/shop/province/retail/template";
      }else{
        this.uploadUrl = config.hosts.request + "api/shop/distributor/retail/import";
        this.templateUrl = "api/shop/distributor/retail/template";
      }
    },
    handleBeforeUpload() {
      this.uploading = true;
    },
    handleUploadSuccess(res) {      
      this.sucInfo = res;
      this.errList = res.errList;
      this.uploading = false;
      if(res.sucCount && res.sucCount>0){      
        this.$parent.init();      
      }
    },
    handleUploadError(err) {
      console.log(err);
      this.errInfo = err;
      this.uploading = false;
    },    
    downloadTemplate(){
      this.downloadLoading = true;
      download(this.templateUrl)
        .then(result => {
          downloadFile(result, "零售价模板", "xlsx");
          this.downloadLoading = false;
        })
        .catch(err => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
    resetForm(){
      this.uploadHeaders.Authorization = "Bearer " + getToken();
      this.sucInfo = null;
      this.dialog = true;
    }
  }
}
</script>